<template>
  <div>
    <el-tabs
      class="page-tabs"
      type="card"
      v-model="activeTab"
      @tab-click="tabChangeHandle"
    >
      <el-tab-pane
        :label="item.label"
        :name="item.value"
        v-for="(item, index) in CONFIG_OPTIONS"
        :key="index"
      >
        <!-- name和type要保持一致 -->
        <panel
          :brandId="item.value"
          @add="addHandle"
          @edit="editHandle"
          :ref="
            (el) => {
              panelRefs[item.value] = el;
            }
          "
        ></panel>
      </el-tab-pane>
    </el-tabs>

    <EditDialog
      v-model="dialogVisible"
      @refresh="refreshHandle"
      :editData="editData"
      :mode="editMode"
    ></EditDialog>
  </div>
</template>
<script>
import { PRINT_BRAND_OPTIONS } from "@/helpers/constants";
import panel from "./panel";
import EditDialog from "./editDialog";
export default {
  components: { panel, EditDialog },
  data() {
    return {
      CONFIG_OPTIONS:[{ label: "全部", value: "all" },...PRINT_BRAND_OPTIONS],
      activeTab: "",
      dialogVisible: false,
      editData: {},
      editMode: "add",
      panelRefs: {},
    };
  },
  created() {
    this.activeTab = this.CONFIG_OPTIONS.length
      ? this.CONFIG_OPTIONS[0].value
      : "";
  },
  activated() {
    this.refreshHandle();
  },
  methods: {
    setItemRef(el, key) {
      this.panelRefs[key] = el;
    },
    tabChangeHandle({ props }) {
      const { name } = props;
      this.activeTab = name;
      this.refreshHandle();
    },
    addHandle() {
      this.editMode = "add";
      this.dialogVisible = true;
    },
    editHandle(row) {
      console.log("editHandle", row);
      this.editMode = "edit";
      this.editData = row;
      this.dialogVisible = true;
    },
    refreshHandle() {
      // console.log(this.panelRefs);
      this.panelRefs[this.activeTab].getList();
    },
  },
};
</script>

<style lang="scss" scoped></style>
